<template>
  <div>

    <b-card
      no-body
    >
      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-between"
      >

        <h2 class="font-weight-normal">
          Family Form Builder
        </h2>

        <div
          class="d-flex align-items-center justify-content-between"
        >
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="!canUpdateForm"
            @click="saveForm"
          >
            Save Changes
            <feather-icon
              icon="SaveIcon"
              size="16"
              class="text-white"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            :to="{ name: 'admin-family-form-folders' }"
          >
            Cancel
          </b-button>
        </div>
      </div>

      <div
        v-if="isLoading"
        style="padding: 20px"
      >
        Loading...
      </div>

      <template
        v-else
      >
        <validation-observer
          :ref="`base-form-builder`"
          tag="form"
        >
          <b-row
            v-if="errorMessage && showError"
            class="my-2 ml-2"
          >
            <b-col md="6">
              <small class="text-danger">
                {{ errorMessage }}
              </small>
            </b-col>
          </b-row>
          <b-row class="mt-2 ml-2 mb-2">
            <b-col md="4">
              <b-form-group
                label="Form Name"
                label-for="form-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Form Name"
                  rules="required"
                >
                  <b-form-input
                    id="form-name"
                    v-model="formName"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              xl="2"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              right
            >
              <label
                class="mr-1 mb-0 col-form-label"
              >Active
              </label>
              <b-form-checkbox
                v-model="activeStatus"
                switch
                class="d-inline-block ml-50 mr-1"
              >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </validation-observer>
      </template>

    </b-card>

    <b-card
      no-body
    >
      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-between"
      >

        <h4 class="font-weight-normal">
         Parent Section
        </h4>

        <div
          class="d-flex align-items-center justify-content-between"
        >
          <div class="action-button">
            <b-dropdown
              toggle-class="p-0 mr-1"
              variant="primary"
              dropleft
              no-caret
              right
            >
              <template
                #button-content
              >
                <feather-icon
                  icon="GridIcon"
                  size="19"
                  class="m-50"
                />
              </template>

              <b-dropdown-item
                @click="addNewFieldsRow('parent')"
              >
                Add Field
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal="'add-condition-'+ type"
              >
                Add Condition
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div
        v-if="isLoading"
        style="padding: 20px"
      >
        Loading...
      </div>

      <template
        v-else
      >
        <validation-observer
          :ref="`parent-section-form-builder`"
          tag="form"
        >

          <!-- Fields Group -->
          <draggable
            v-model="parentsFieldsList"
            tag="ul"
            class="list-group list-group-flush cursor-move"
          >
            <FormBuilderRow
              v-for="(item, index) in parentsFieldsList"
              ref="formBuilderRow"
              :key="item.frontID + item.id"
              :fields-list="parentsFieldsList"
              :fields-data="item"
              :field-index="index"
              :following-field="followingField"
              :form-builder-type="'family'"
              @removeFieldsRow="removeFieldsRow($event, 'parent')"
              @removeCondition="removeCondition"
              @moveItem="moveItem"
              @closeOpenedContextMenu="closeOpenedContextMenu"
            />
          </draggable>
        </validation-observer>
      </template>

    </b-card>

    <b-card
      no-body
    >
      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-between"
      >

        <h4 class="font-weight-normal">
          Family Data Section
        </h4>

        <div
          class="d-flex align-items-center justify-content-between"
        >
          <div class="action-button">
            <b-dropdown
              toggle-class="p-0 mr-1"
              variant="primary"
              dropleft
              no-caret
              right
            >
              <template
                #button-content
              >
                <feather-icon
                  icon="GridIcon"
                  size="19"
                  class="m-50"
                />
              </template>

              <b-dropdown-item
                @click="addNewFieldsRow('family')"
              >
                Add Field
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal="'add-condition-'+ type"
              >
                Add Condition
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div
        v-if="isLoading"
        style="padding: 20px"
      >
        Loading...
      </div>

      <template
        v-else
      >
        <validation-observer
          :ref="`family-section-form-builder`"
          tag="form"
        >

          <!-- Fields Group -->
          <draggable
            v-model="familyDataFieldsList"
            tag="ul"
            class="list-group list-group-flush cursor-move"
          >
            <FormBuilderRow
              v-for="(item, index) in familyDataFieldsList"
              ref="formBuilderRow"
              :key="item.frontID + item.id"
              :fields-list="familyDataFieldsList"
              :fields-data="item"
              :field-index="index"
              :following-field="followingField"
              :form-builder-type="'family'"
              @removeFieldsRow="removeFieldsRow($event, 'family')"
              @removeCondition="removeCondition"
              @moveItem="moveItem"
              @closeOpenedContextMenu="closeOpenedContextMenu"
            />
          </draggable>
        </validation-observer>
      </template>

    </b-card>

    <AddConditionModal
      :type="type"
      :condition-data="conditions"
      :fields-data="fieldsForConditions"
      @saveConditions="setFormConditions"
    />

    <MoveBeforeFieldModal
      :type="type"
      :fields-list="fieldsList"
      :field-to-move="fieldToMove"
      @moveItemBefore="moveItemBefore"
    />

  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { formConditionsMethods } from '@core/mixins/formConditionsMethods'
import { v4 as uuidv4 } from 'uuid'

import store from '@/store'
import useFormBuilder from '@/views/components/form-builder/useFormBuilder'
import FormBuilderRow from '@/views/components/form-builder/FormBuilderRow.vue'
import MoveBeforeFieldModal from '@/views/components/form-builder/modals/MoveBeforeFieldModal.vue'
import AddConditionModal from '@/views/components/form-builder/modals/AddConditionModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'FormBuilder',
  components: {
    ValidationObserver,
    ValidationProvider,

    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,

    draggable,

    FormBuilderRow,
    AddConditionModal,
    MoveBeforeFieldModal,
  },
  mixins: [formConditionsMethods],
  props: {
    type: {
      type: String,
      required: true,
    },
    formId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      formBuilderFieldTypes,
      postForm,
      updForm,
      fetchForm,
      fetchFollowing,
      fetchGroupTypes,
    } = useFormBuilder()

    return {
      formBuilderFieldTypes,
      postForm,
      updForm,
      fetchForm,
      fetchFollowing,
      fetchGroupTypes,
    }
  },
  data() {
    return {
      selected: null,
      fieldsList: [],
      conditions: [],
      fieldsForConditions: [],
      fieldsCount: 0,
      activeStatus: false,
      isLoading: false,
      followingField: [],
      formName: null,
      formType: null,
      errorMessage: '',
      fieldToMove: {
        label: '',
      },

    }
  },
  computed: {
    showError() {
      return this.parentsFieldsList.length === 0 && this.familyDataFieldsList.length === 0
    },
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    canUpdateForm() {
      if (this.formId) {
        return this.$can('update', permissionSubjects.Form)
      }
      return true
    },
    parentsFieldsList: {
      get() {
        return this.fieldsList.filter(field => field.section_type === 'parent')
      },
      set(val) {
        this.fieldsList = this.fieldsList
          .sort((a, b) => {
            if (val.indexOf(b) === -1) {
              return 1
            }
            return val.indexOf(a) - val.indexOf(b)
          })
      },
    },
    familyDataFieldsList: {
      get() {
        return this.fieldsList.filter(field => field.section_type === 'family')
      },
      set(val) {
        this.fieldsList = this.fieldsList
          .sort((a, b) => {
            if (val.indexOf(b) === -1) {
              return 1
            }
            return val.indexOf(a) - val.indexOf(b)
          })
      },
    },
  },
  watch: {
    fieldsList: {
      deep: true,
      handler(val) {
        this.fieldsCount = this.fieldsList.length
        this.fieldsForConditions = this.setFieldsForConditions(val)
      },
    },
  },
  async created() {
    if (this.formId) await this.setForm(this.formId)

    this.followingField = await this.fetchFollowing()
      .then(response => response.reduce((arr, item) => {
        arr.push({ value: item.field_name, text: item.field_name })
        return arr
      }, []))

    this.fieldsForConditions = this.setFieldsForConditions(this.fieldsList)

    this.fieldsList.sort((a, b) => a.order - b.order)
  },
  methods: {
    setForm(val) {
      this.fetchForm(val)
        .then(response => {
          if (response === 404) {
            this.isLoading = true
            return
          }

          this.activeStatus = response.is_active
          this.formName = response.name
          this.formType = response.type
          this.fieldsList = response.fields.map(item => (
            {
              isCondition: false,
              isVisibility: false,
              ...item,
            }
          ))

          this.fieldsCount = this.fieldsList.length

          this.isLoading = false

          this.setFormConditions(response.conditions)
        })
    },
    isFieldKeyExists(key, type) {
      return this.fieldsList.filter(field => field.section_type === type).some(field => field.name === `field_${key}`)
    },
    addNewFieldsRow(type) {
      do {
        this.fieldsCount += 1
      } while (this.isFieldKeyExists(this.fieldsCount, type))

      this.fieldsList.push({
        id: uuidv4(),
        type: null,
        label: `Field ${this.fieldsCount}`,
        name: `field_${this.fieldsCount}`,
        required: false,
        use_prefill: false,
        options: [],
        default: '',
        order: this.fieldsCount,
        isCondition: false,
        isVisibility: false,
        attribute_field_name: null,
        section_type: type,
      })
    },
    removeFieldsRow(ID) {
      const curItem = this.fieldsList.findIndex(item => item.id === ID)
      this.fieldsList.splice(curItem, 1)
    },
    validateForm() {
      const PROMISES = [
        this.$refs['base-form-builder'],
        this.$refs['parent-section-form-builder'],
        this.$refs['family-section-form-builder'],
      ]
      return Promise.all(PROMISES)
    },
    async saveForm() {
      if (this.fieldsList.length < 1) {
        this.errorMessage = 'Please, add some field before safe form'
        return
      }

      this.validateForm().then(values => {
        const formIsValid = values.every(result => result)

        if (formIsValid) {
          this.saveData()
        }
      })
    },
    async saveData() {
      this.errorMessage = ''
      this.isLoading = true

      const fields = this.fieldsList.filter(item => item.type !== 'checkbox' || (item.type === 'checkbox' && item.options[0]))

      const updatedData = {
        fields,
        conditions: this.conditions,
        type: this.formType || this.type,
        name: this.formName,
        is_active: this.activeStatus,
      }

      if (this.formId) {
        updatedData.id = this.formId
        await this.updForm(updatedData).then(() => {
          this.setForm(this.formId)
        }).finally(() => {
          this.isLoading = false
        })
        return
      }

      await this.postForm(updatedData).then(response => {
        this.isLoading = false
        this.$emit('createdForm', response.id)
      })
    },
    moveItem(param) {
      this.fieldToMove = param.item
      if (param.action === 'moveToEnd') {
        this.fieldsList.push(this.fieldsList.splice(this.fieldsList.indexOf(this.fieldToMove), 1)[0])
      } else if (param.action === 'moveToBeginning') {
        this.fieldsList.unshift(this.fieldsList.splice(this.fieldsList.indexOf(this.fieldToMove), 1)[0])
      } else {
        this.$bvModal.show(`move-before-field-${this.type}`)
      }
    },
    moveItemBefore(field) {
      const oldIndex = this.fieldsList.indexOf(this.fieldToMove)
      const newIndex = this.fieldsList.findIndex(item => item.id === field.id)
      this.fieldsList.splice(oldIndex, 1)
      if (oldIndex < newIndex) {
        this.fieldsList.splice(newIndex - 1, 0, this.fieldToMove)
      } else {
        this.fieldsList.splice(newIndex, 0, this.fieldToMove)
      }
    },
    closeOpenedContextMenu() {
      this.$refs.formBuilderRow.forEach(row => {
        row.$refs.menu.close()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .header,
  .card-footer {
    border-top-right-radius: calc(0.428rem - 1px);
    border-top-left-radius: calc(0.428rem - 1px);
    padding: 1.45rem 2.25rem;
    background-color: #fff;
  }
  .header {
      border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }
  .card-footer {
      border-top: 1px solid rgba(34, 41, 47, 0.125) !important;
  }
  .type-pill {
    padding: 10px;
    background-color: #828589;
    color: white;
    text-align: center;
    border-radius: 10px;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }

  .action-button{
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 100;
  }
  .fixed{
    position: fixed;
    transform: translateX(-100%);
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
